<template>
  <b-modal
    id="modal-fee"
    ref="refModal"
    :title="t('Dịch vụ')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col :md="itemLocal.default ? 12 : 6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                :label="t('Tên dịch vụ')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên dịch vụ') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-input-group>

                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Tiền ăn"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="feeCalculationTypeObject"
              rules="required"
            >
              <select-fee-calculation-type
                v-model="itemLocal.feeCalculationTypeObject"
                required
                :default-value="itemLocal.feeCalculationTypeObject"
                :label="t('Cách tính phí')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>
        <b-row v-if="itemLocal.feeCalculationTypeObject && itemLocal.feeCalculationTypeObject.value === 'fixed_by_quantity'">

          <b-col>
            <validation-provider
              #default="validationContext"
              name="price"
              rules="required"
            >
              <number-input
                v-model="itemLocal.price"
                required
                :default-value="itemLocal.price"
                :label="t('Đơn giá')"
                placeholder="100,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="identityObject"
              rules="required"
            >
              <select-fee-identity
                v-model="itemLocal.identityObject"
                required
                :default-value="itemLocal.identityObject"
                :label="t('Loại dịch vụ')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="feeTypeObject"
              rules="required"
            >
              <select-fee-type
                v-model="itemLocal.feeTypeObject"
                required
                :default-value="itemLocal.feeTypeObject"
                :label="t('Loại thu chi')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="t('Mô tả')">
              <b-form-textarea
                id="description"
                v-model="itemLocal.description"
                :placeholder="t('Mô tả')"
                rows="3"
              />
            </b-form-group>

          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <select-multiple-school
              v-model="itemLocal.schools"
              :default-value="itemLocal.schools"
              label="Chọn cơ sở"
              class="select-school"
            />
          </b-col>
        </b-row>
        <b-row v-if="enableViettelInvoice">

                    <!-- Active/Inactive -->
                    <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.enableIssueInvoice"
                name="check-button"
                switch
                inline
              >
                {{ itemLocal.enableIssueInvoice ? 'Xuất hóa đơn' : 'Không xuất hóa đơn' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BFormTextarea,
  BFormCheckbox
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectMultipleSchool from '@/views/components/SelectMultipleSchool.vue';
import SelectFeeIdentity from '@/views/components/SelectFeeIdentity.vue';
import useFeeModal from './useFeeModal';
import SelectFeeCalculationType from '../../components/SelectFeeCalculationType.vue';
import SelectFeeType from '../../components/SelectFeeType.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    NumberInput,
    SelectFeeCalculationType,
    SelectFeeType,
    SelectFeeIdentity,
    SelectMultipleSchool,
    BFormCheckbox
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit,
      enableViettelInvoice
    } = useFeeModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      enableViettelInvoice,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
