<template>
  <b-form-group :label="label" :state="state">
    <template v-if="label" v-slot:label>
      {{ label }}
      <span v-if="required" class="text-danger"> (*) </span>
    </template>
    <v-select
      id="type"
      v-model="type"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="types"
      placeholder="Phân loại"
      :disabled="!enable"
      @input="onChanged"
    />
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: this.defaultValue,
      types: [
        { title: 'Đơn giá cố định theo lượt', value: 'fixed_by_quantity' },
        { title: 'Đơn giá tùy chỉnh theo tháng', value: 'dynamic' },
      ],
    };
  },

  methods: {
    onChanged() {
      this.$emit('input', this.type);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
