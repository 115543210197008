import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import feeStoreModule from '../feeStoreModule';
import { getUserConfiguration } from '@/auth/utils';

export default function useFeeModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'fee';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, feeStoreModule);
  }

  const userConfiguration = getUserConfiguration()
  const enableViettelInvoice = ref(userConfiguration && userConfiguration.useViettelInvoice ? true : false)

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };
  const { t } = useI18nUtils();
  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    console.log(itemLocal.value);

    const data = {
      name: itemLocal.value.name,
      description: itemLocal.value.description,
      price: itemLocal.value.feeCalculationTypeObject && itemLocal.value.feeCalculationTypeObject.value === 'fixed_by_quantity' ? itemLocal.value.price : 0,
      enableIssueInvoice: itemLocal.value.enableIssueInvoice
    };

    if (itemLocal.value.feeTypeObject) {
      data.feeType = itemLocal.value.feeTypeObject.value;
    }

    if (itemLocal.value.identityObject) {
      data.identity = itemLocal.value.identityObject.id;
    }

    if (itemLocal.value.feeCalculationTypeObject) {
      data.feeCalculationType = itemLocal.value.feeCalculationTypeObject.value;
    }
    if (itemLocal.value.schools) {
      data.schoolIds = itemLocal.value.schools.map(_obj => _obj.id);
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('fee/updateFee', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('fee/createFee', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
    enableViettelInvoice
  };
}
